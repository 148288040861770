import React from 'react'

const StoreH = React.lazy(() => import('./storeH'))
const AccountH = React.lazy(() => import('./accountH'))
const ReaderRewardSheet = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/ReaderRewardSheet')
)
const OfferBottomSheet = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/OfferBottomSheet')
)
const CoinsBottomSheet = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/CoinsBottomSheet')
)
const ShowsBottomSheet = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/ShowsBottomSheet')
)
const StoreB = React.lazy(() => import('./storeB'))
const StoreF = React.lazy(() => import('./storeF'))
const Store3 = React.lazy(() => import('components/ReaderFlowIos/store3'))
const AccountF = React.lazy(() => import('./accountF'))
const Account3 = React.lazy(() => import('components/ReaderFlowIos/account3'))

const ReaderV5Routes = [
  {
    path: 'reader-reward-sheet',
    element: <ReaderRewardSheet />,
  },
  {
    path: 'reader-shows-sheet/:show_id',
    element: <ShowsBottomSheet />,
  },
  {
    path: 'reader-offer-sheet',
    element: <OfferBottomSheet />,
  },
  {
    path: 'reader-coins-sheet',
    element: <CoinsBottomSheet />,
  },
  ...['reader-storeb', 'reader-accountb'].map((pathUrl) => ({
    path: pathUrl,
    element: <StoreB />,
  })),
  ...['reader-storec', 'reader-stored', 'reader-storee', 'reader-storeg'].map(
    (pathUrl) => ({
      path: pathUrl,
      element: <Store3 />,
    })
  ),
  ...['reader-storeh', 'reader-storei'].map((pathUrl) => ({
    path: pathUrl,
    element: <StoreH />,
  })),
  ...[
    'reader-accountc',
    'reader-accountd',
    'reader-accounte',
    'reader-accountg',
  ].map((pathUrl) => ({
    path: pathUrl,
    element: <Account3 />,
  })),
  ...['reader-accounth', 'reader-accounti'].map((pathUrl) => ({
    path: pathUrl,
    element: <AccountH />,
  })),
  {
    path: 'reader-accountf',
    element: <AccountF />,
  },
  {
    path: 'reader-storef',
    element: <StoreF />,
  },
]

export { ReaderV5Routes }
