import GitInfo from 'react-git-info/macro'

import './style.css'

const GetBranchName = () => {
  const gitInfo = GitInfo()
  if (!gitInfo.branch || gitInfo.branch == 'master') {
    return null
  }
  return <div className="siteInfo">{gitInfo.branch}</div>
}

export default GetBranchName
