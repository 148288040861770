const isProd = window.location.hostname.includes('payments.pocketfm.in')
const BASE_URL = 'https://api.pocketfm.in'
const BASE_URL_With_COM = 'https://api.pocketfm.com'
const QA_URL = 'http://api-qa-consumer2.pocketfm.com'
const QA_URL_With_HTTPS = 'https://api-qa-consumer2.pocketfm.com'
const QA_Stripe_Android_Host_Name = 'hritik'

export {
  BASE_URL,
  isProd,
  QA_URL,
  QA_URL_With_HTTPS,
  QA_Stripe_Android_Host_Name,
  BASE_URL_With_COM,
}
