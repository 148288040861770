/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationDE from './locales/de/translation.json'
import translationFR from './locales/fr/translation.json'
import translationIT from './locales/it/translation.json'
import translationMX from './locales/mx/translation.json'
import translationPT from './locales/pt/translation.json'
import translationUS from './locales/us/translation.json'

const getLanguageFromUrl = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const locale = queryParams.get('locale') || 'US'

  return locale?.toLowerCase()
}

const resources = {
  de: { ...translationDE },
  fr: { ...translationFR },
  it: { ...translationIT },
  mx: { ...translationMX },
  pt: { ...translationPT },
  us: { ...translationUS },
}

void i18n.use(initReactI18next).init({
  resources,
  lng: getLanguageFromUrl(),
  fallbackLng: 'us',
  debug: false,
  interpolation: {
    escapeValue: false, // React already escapes
  },
})

export default i18n
