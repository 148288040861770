import React from 'react'

const FreeShowBanner = React.lazy(
  () => import('components/ReaderFlowIos/Banner/FreeShowBanner')
)
const DiscountShowsBanner = React.lazy(
  () => import('components/ReaderFlowIos/Banner/DiscountShowsBanner')
)
const DiscountShowsBannerTwo = React.lazy(
  () => import('components/ReaderFlowIos/Banner/DiscountShowsBannerTwo')
)
const RewardBanner = React.lazy(
  () => import('components/ReaderFlowIos/Banner/RewardBanner')
)
const DiscountCoinsBanner = React.lazy(
  () => import('components/ReaderFlowIos/Banner/DiscountCoinsBanner')
)
const DiscountCoinsBannerTwo = React.lazy(
  () => import('components/ReaderFlowIos/Banner/DiscountCoinsBannerTwo')
)
const ReaderBannerRoutes = [
  {
    path: 'reward-banner',
    element: <RewardBanner />,
  },
  {
    path: 'extra-discount-banner/:variant',
    element: <DiscountCoinsBanner />,
  },
  {
    path: 'free-shows-banner/:show_id',
    element: <FreeShowBanner />,
  },
  {
    path: 'shows-discount-banner/:show_id',
    element: <DiscountShowsBanner />,
  },
  {
    path: 'thirty-discount-banner/:show_id',
    element: <DiscountShowsBannerTwo />,
  },
  {
    path: 'discount-banner/:variant',
    element: <DiscountCoinsBannerTwo />,
  },
]

export { ReaderBannerRoutes }
