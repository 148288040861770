import { Suspense, useEffect } from 'react'

import * as Sentry from '@sentry/react'

import GetBranchName from 'components/GetBranchName/GetBranchName'
import { AppConfigProvider } from 'Providers/AppConfigProvider'

import Loader from './components/LoadComponent'
import RootBoundary from './components/RootBoundary'
import Router from './Router'

import './i18n'
import './App.css'

import { Toaster } from '@/components/ui/toaster'

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    }
  }, [])
  return (
    <div className="App">
      <Sentry.ErrorBoundary fallback={RootBoundary}>
        <Suspense fallback={<Loader />}>
          <AppConfigProvider>
            <GetBranchName />
            <Toaster />
            <Router />
          </AppConfigProvider>
        </Suspense>
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default App
