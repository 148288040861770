import './style.css'

const Loader = () => {
  return (
    <div className="newDiv">
      <div className="loader1"></div>
    </div>
  )
}

export default Loader
