import { useState } from 'react'

import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import 'components/NotFound/style.css'

function RootBoundary() {
  const error = useRouteError()
  const [errorMsg, setErrorMsg] = useState('Something went wrong')

  const closeWebView = () => {
    if (window.Android && window.Android.closePage) {
      window.Android.closePage()
    }
    if (window.webkit && window.webkit.messageHandlers.closeButtonClicked) {
      window.webkit.messageHandlers.closeButtonClicked.postMessage('')
    }
  }

  if (isRouteErrorResponse(error)) {
    const errorObject = {
      404: "This page doesn't exist!",
      401: "You aren't authorized to see this",
      503: 'Looks like our API is down',
      418: '🫖',
    }

    const errorMessage = errorObject[error.status] || 'Something went wrong'

    setErrorMsg(errorMessage)
  }

  return (
    <div
      className="not_found_container"
      style={{
        backgroundImage: `url('/images/landing_background_image.webp')`,
      }}
    >
      <h3 className="not_found_title">{errorMsg}</h3>
      <button className="not_found_back_btn" onClick={closeWebView}>
        Go Back
      </button>
    </div>
  )
}

export default RootBoundary
