import React from 'react'

const VariantB = React.lazy(
  () => import('components/ReaderFlowIos/v6/VariantB')
)
const VariantC = React.lazy(
  () => import('components/ReaderFlowIos/v6/VariantC.tsx')
)
const ShowsBottomSheetThree = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/ShowsBottomSheetThree')
)
const ShowsBottomSheetFour = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/ShowsBottomSheetFour')
)
const DiscountCoinsSheet = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/DiscountCoinsSheet')
)
const DiscountCoinsSheetTwo = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/DiscountCoinsSheetTwo')
)
const ShowsBottomSheetTwo = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/ShowsBottomSheetTwo')
)
const ReaderRewardSheetTwo = React.lazy(
  () => import('components/ReaderFlowIos/BottomSheet/ReaderRewardSheetTwo')
)

const ReaderV6Routes = [
  {
    path: 'B/:variant',
    element: <VariantB />,
  },
  {
    path: 'C/:variant',
    element: <VariantC />,
  },
  {
    path: 'D/:variant',
    element: <VariantC />,
  },
  {
    path: 'reader-reward-sheet',
    element: <ReaderRewardSheetTwo />,
  },
  {
    path: 'free-shows-sheet/:show_id',
    element: <ShowsBottomSheetTwo />,
  },
  {
    path: 'extra-discount-sheet/:variant',
    element: <DiscountCoinsSheet />,
  },
  {
    path: 'shows-discount-sheet/:show_id',
    element: <ShowsBottomSheetThree />,
  },
  {
    path: 'thirty-discount-sheet/:show_id',
    element: <ShowsBottomSheetFour />,
  },
  {
    path: 'discount-sheet/:variant',
    element: <DiscountCoinsSheetTwo />,
  },
]

export { ReaderV6Routes }
