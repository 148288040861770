import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import PropTypes from 'prop-types'

import { isProd } from 'utils'

const AppConfigContext = createContext({})

const AppConfigProvider = ({ children }) => {
  const preprodAPI = 'https://preprodapi.pocketfm.com'
  const [apiData, setApiData] = useState({
    QA_STRIPE_URL_With_HTTPS: preprodAPI,
    QA_STRIPE_URL: preprodAPI,
    QA_ACCOUNT_CLOSURE: preprodAPI,
    QA_BATTLEPASS: preprodAPI,
    QA_JUSPAY: preprodAPI,
    QA_READERSTORE: preprodAPI,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [userLocale, setUserLocale] = useState('en-US')

  const onUserLocale = useCallback((value) => {
    setUserLocale(value)
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://qa-web.pocketfm.com/stripe_webview_config?is_prod=${isProd}`,
          {
            method: 'GET',
          }
        )

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const responseData = await response.json()
        setApiData({
          ...apiData,
          ...responseData,
        })
        setIsLoading(false)
      } catch (err) {
        console.error(err)
      }
    }

    if (!isProd) {
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <AppConfigContext.Provider
      value={{
        ...apiData,
        isLoading,
        userLocale,
        onUserLocale,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}

AppConfigProvider.propTypes = {
  children: PropTypes.node,
}

const useAppConfig = () => {
  return useContext(AppConfigContext)
}

export { AppConfigContext, AppConfigProvider, useAppConfig }
