import React from 'react'

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { ReaderBannerRoutes } from 'components/ReaderFlowIos/Banner'
import { ReaderV4Routes } from 'components/ReaderFlowIos/v4'
import { ReaderV5Routes } from 'components/ReaderFlowIos/v5'

import { ReaderV6Routes } from './components/ReaderFlowIos/v6'

const LoyaltyProgramPro = React.lazy(
  () => import('components/LoyaltyProgramPro')
)

const Root = React.lazy(() => import('./components/Root'))
const ReadersStore1 = React.lazy(
  () => import('components/ReaderFlowIos/store1')
)
const ReadersStore2 = React.lazy(
  () => import('components/ReaderFlowIos/store2')
)
const ReadersStore3 = React.lazy(
  () => import('components/ReaderFlowIos/store3')
)
const Account1 = React.lazy(() => import('./components/ReaderFlowIos/account1'))
const Account2 = React.lazy(() => import('./components/ReaderFlowIos/account2'))
const Account3 = React.lazy(() => import('./components/ReaderFlowIos/account3'))

const PocketVipPlans = React.lazy(() => import('./components/PocketVipPlans'))
const PaymentForm = React.lazy(
  () => import('./components/PaymentFormComponent')
)
const StandalonePaymentForm = React.lazy(
  () => import('./components/StandalonePaymentFormComponent')
)
const PaymentSuccess = React.lazy(
  () => import('./components/PaymentSuccessComponent')
)
const PaymentFail = React.lazy(
  () => import('./components/PaymentFailComponent')
)
const RenewPocketVip = React.lazy(
  () => import('./components/RenewPocketVipComponent')
)
const PrimeUnlocked = React.lazy(
  () => import('./components/PrimeUnlockedComponent')
)
const HomeIOS = React.lazy(() => import('./components/HomeIOS'))
const PaypalCheckout = React.lazy(() => import('./components/PaypalCheckout'))
const PiggyBankFAQs = React.lazy(() => import('./components/piggyBankFaqs'))
const LuckyDraw = React.lazy(() => import('./components/LuckyDraw/LuckyDraw'))
const LuckyDrawCampaign = React.lazy(
  () => import('./components/LuckyDrawDetails/LuckyDrawCampaign')
)
const OfferWall = React.lazy(() => import('./components/OfferWall/OfferWall'))
const OfferWallRewamp = React.lazy(
  () => import('./components/OfferWallRewamp/OfferWallRewamp')
)
const OfferWallDetail = React.lazy(
  () => import('./components/OfferWall/OfferWallDetail/OfferWallDetail')
)
const RewardStatus = React.lazy(
  () => import('./components/OfferWall/RewardStatus')
)
const BattlePass = React.lazy(() => import('./components/BattlePass'))
const CouponOffer = React.lazy(() => import('./components/CouponOffer'))
const Success = React.lazy(() => import('./components/CouponOffer/Success'))
const CouponOfferPartnership = React.lazy(
  () => import('./components/CouponOffer/CouponOfferPartnership')
)
const HelpSupport = React.lazy(() => import('./components/HelpSupport'))
const OfferSuccess = React.lazy(
  () => import('./components/CouponOffer/offerSuccess')
)
const StripeCheckout = React.lazy(() => import('./components/StripeCheckout'))
const StripeSuccess = React.lazy(
  () => import('./components/StripeCheckout/StripeSuccess')
)
const StripeOptimisedSuccess = React.lazy(
  () => import('./components/StripeOptimizedCheckoutFM/StripeSuccess')
)
const StripeCheckoutFm = React.lazy(
  () => import('./components/StripeCheckoutFm')
)
const StripeOptimisedCheckoutFm = React.lazy(
  () => import('./components/StripeOptimizedCheckoutFM')
)
const StripeInAppCheckoutFm = React.lazy(
  () => import('./components/StripeInAppCheckoutFm')
)
const StripeInAppSuccessFm = React.lazy(
  () => import('./components/StripeInAppCheckoutFm/StripeInAppSuccess')
)
// const StripeAndroidFm = React.lazy(() =>
//   import("./components/StripeAndroidFm")
// );
const StripeAndroidSuccessFm = React.lazy(
  () => import('./components/StripeAndroidFm/StripeAndroidSuccess')
)
const PaypalCheckoutFm = React.lazy(
  () => import('./components/PaypalCheckoutFm')
)
const JuspayCheckoutFm = React.lazy(
  () => import('./components/JuspayCheckoutFm')
)
const StripeCheckoutWalletFm = React.lazy(
  () => import('./components/StripeWallet')
)
const StripeSuccessFm = React.lazy(
  () => import('./components/StripeCheckoutFm/StripeSuccess')
)
const PaypalSuccessFm = React.lazy(
  () => import('./components/PaypalCheckoutFm/PaypalSuccess')
)
const JuspaySuccessFm = React.lazy(
  () => import('./components/JuspayCheckoutFm/JuspaySuccess')
)
const CoinGuide = React.lazy(() => import('./components/CoinGuide/CoinGuide'))
const StripeSaveCardCheckout = React.lazy(
  () => import('./components/StripeSaveCardCheckoutFm')
)
const VideoCoinGuide = React.lazy(
  () => import('./components/VideoCoinGuide/VideoCoinGuide')
)
const AppleFile = React.lazy(() => import('./components/AppleFile/AppleFile'))
const LoyaltyProgram = React.lazy(() => import('./components/LoyaltyProgram'))
const ShowPassPack = React.lazy(
  () => import('./components/ShowPassPack/ShowPassPack')
)
const DailyPassPack = React.lazy(
  () => import('./components/DailyPassPack/DailyPassPack')
)
const LoyaltyProgramNovel = React.lazy(
  () => import('./components/LoyaltyProgramNovel')
)
const AccountClosure = React.lazy(() => import('./components/AccountClosure'))
const NotFound = React.lazy(() => import('./components/NotFound'))
const RootBoundary = React.lazy(() => import('./components/RootBoundary'))
const ShowStreaks = React.lazy(
  () => import('./components/ShowStreaks/ShowStreaks')
)
const StudentPass = React.lazy(() => import('./components/StudentPass'))

const UserSubscriptionDetails = React.lazy(
  () => import('components/UserSubscriptionDetails')
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/pocket_vip_plans',
    element: <PocketVipPlans />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/student_pass',
    element: <StudentPass />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/payment_form',
    element: <PaymentForm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/standalone_payment_form',
    element: <StandalonePaymentForm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/payment_success',
    element: <PaymentSuccess />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/payment_failure',
    element: <PaymentFail />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/renew_pocket_vip',
    element: <RenewPocketVip />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/main_page_ios',
    element: <HomeIOS />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/paypal_checkout',
    element: <PaypalCheckout />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/prime_locked',
    element: <PocketVipPlans />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/prime_unlocked/profile/:profile_uid',
    element: <PrimeUnlocked />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/piggy_bank_faqs',
    element: <PiggyBankFAQs />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/address-form',
    element: <LuckyDraw />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/lucky-draw-campaign',
    element: <LuckyDrawCampaign />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/offer-wall',
    element: <OfferWall />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/offerwall-revamp',
    element: <OfferWallRewamp />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/offer-wall-detail/:id',
    element: <OfferWallDetail />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/rewards',
    element: <RewardStatus />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/battle-pass',
    element: <BattlePass />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/coupon-offer',
    element: <CouponOffer />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/coupon-offer-partnership',
    element: <CouponOfferPartnership />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/offer-success',
    element: <OfferSuccess />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/coupon-offer-success',
    element: <Success />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/help-support',
    element: <HelpSupport />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-checkout',
    element: <StripeCheckout />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-success',
    element: <StripeSuccess />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-checkout-fm',
    element: <StripeCheckoutFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-optimised-checkout-fm',
    element: <StripeOptimisedCheckoutFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-inapp-checkout-fm',
    element: <StripeInAppCheckoutFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-inapp-success-fm',
    element: <StripeInAppSuccessFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/paypal-checkout-fm',
    element: <PaypalCheckoutFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/juspay-checkout',
    element: <JuspayCheckoutFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-wallet-checkout-fm',
    element: <StripeCheckoutWalletFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-save-card-checkout-fm',
    element: <StripeSaveCardCheckout />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-success-fm',
    element: <StripeSuccessFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/stripe-optimised-success-fm',
    element: <StripeOptimisedSuccess />,
    errorElement: <RootBoundary />,
  },
  // {
  //   path: "/stripe-android-checkout-fm", //This is no longer used
  //   element: <StripeAndroidFm />,
  //   errorElement: <RootBoundary />
  // },
  {
    path: '/stripe-android-success-fm',
    element: <StripeAndroidSuccessFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/paypal-success-fm',
    element: <PaypalSuccessFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/juspay-success',
    element: <JuspaySuccessFm />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/coin-guide',
    element: <CoinGuide />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/video-coin-guide',
    element: <VideoCoinGuide />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/loyalty-program',
    element: <LoyaltyProgram />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/account-closure',
    element: <AccountClosure />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/loyalty-program-novel',
    element: <LoyaltyProgramNovel />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/loyalty-program-pro',
    element: <LoyaltyProgramPro />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/.well-known/apple-developer-merchantid-domain-association',
    element: <AppleFile />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/show-pass-pack',
    element: <ShowPassPack />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/daily-pass-pack',
    element: <DailyPassPack />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/reader-store1',
    element: <ReadersStore1 />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/reader-store2',
    element: <ReadersStore2 />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/reader-store3',
    element: <ReadersStore3 />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/v4/',
    children: ReaderV4Routes,
    errorElement: <RootBoundary />,
  },
  {
    path: '/v5/',
    children: ReaderV5Routes,
    errorElement: <RootBoundary />,
  },
  {
    path: '/reader-account1',
    element: <Account1 />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/reader-account2',
    element: <Account2 />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/reader-account3',
    element: <Account3 />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/banner/',
    children: ReaderBannerRoutes,
    errorElement: <RootBoundary />,
  },
  {
    path: '/v6/',
    children: ReaderV6Routes,
    errorElement: <RootBoundary />,
  },
  {
    path: '/show-streaks',
    element: <ShowStreaks />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/user-subscription-details',
    element: <UserSubscriptionDetails />,
    errorElement: <RootBoundary />,
  },
  {
    path: '/404',
    element: <NotFound />,
    errorElement: <RootBoundary />,
  },
  {
    path: '*',
    element: <Navigate replace to="/404" />,
    errorElement: <RootBoundary />,
  },
])

function Router() {
  return <RouterProvider router={router} />
}

export default Router
