import React from 'react'

const Store1 = React.lazy(() => import('./store1'))
const Store2 = React.lazy(() => import('./store2'))
const Store3 = React.lazy(() => import('./store3'))
const Store4 = React.lazy(() => import('./store4'))
const Account1 = React.lazy(() => import('./account1'))
const Account2 = React.lazy(() => import('./account2'))
const Account3 = React.lazy(() => import('./account3'))
const Account4 = React.lazy(() => import('./account4'))

const ReaderV4Routes = [
  {
    path: 'reader-store1',
    element: <Store1 />,
  },
  {
    path: 'reader-store2',
    element: <Store2 />,
  },
  {
    path: 'reader-store3',
    element: <Store3 />,
  },
  {
    path: 'reader-store4',
    element: <Store4 />,
  },
  {
    path: 'reader-account1',
    element: <Account1 />,
  },
  {
    path: 'reader-account2',
    element: <Account2 />,
  },
  {
    path: 'reader-account3',
    element: <Account3 />,
  },
  {
    path: 'reader-account4',
    element: <Account4 />,
  },
]

export { ReaderV4Routes }
